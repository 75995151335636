.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

#react-paginate li {
  display: inline-block;
}

.pagination li {
  padding: 4px 8px;
  border-radius: 4px;
}

.pagination li.active {
  background: rgba(0, 0, 0, 0.3);
}
.textDate {
  margin-top: 22px !important;
}
.d_progress_bar_non {
  display: none !important;
}
.dashboard_table_mob {
  display: none !important;
}
hr.dashboard_table_mob {
  display: none !important;
}
.dashboard_table_mob_desc {
  display: table-cell !important;
}
.dashboard_table_des {
  display: contents !important;
}
.home_box__keyword {
  box-shadow: 0px 0px 1px 1px rgb(133 133 179 / 14%) !important;
  padding: 25px 30px !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  margin-bottom: 0px !important;
}
.dashboard_table_des_table {
  display: table-cell !important;
}
.section__payment .payment-box .card .info ul li .flex__items {
  padding: 10px 0;
  flex: 1 1;
}
.section__payment .payment-box .card .info ul {
  display: flex;
  flex-wrap: wrap;
}
.section__payment .payment-box .card .info ul li {
  border-color: currentcolor #e8e8e8 #e8e8e8 !important;
  border-top: 1px solid #e8e8e8 !important;
  display: flex !important;
  width: 50%;
}
.section__payment .payment-box .card .info ul li .flex__items:nth-of-type(2) {
  border-left: 1px solid #f0f0f0;
  padding-left: 15px;
  margin-left: 20px;
}
.navbar {
  padding: 12px 0 !important;
}
.tr_border {
  border-bottom: 1px solid #eaeaea !important;
}
.PricingIntro {
  font-size: 14px !important;
  color: #8d8d8d !important;
  letter-spacing: 0.5pt;
}
.emailnotification {
  display: none !important;
}
.navbar {
  padding: 15px 0 !important;
}
.widgetDeleteButton {
  margin-left: 3em !important;
  margin-bottom: 0 !important;
}
.select__options2 .select__control {
  width: 200px;
}
.select__options .select__control {
  width: 200px;
}
.select__options {
  z-index: 1000;
}
#exampleFormControlSelect1 {
  background: white;
}

.manage-group-scroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.create-widget_image {
  border: 1px solid #959595;
  border-top-color: rgb(149, 149, 149);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgb(149, 149, 149);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgb(149, 149, 149);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgb(149, 149, 149);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  cursor: pointer;
  width: 120px;
  height: 90px;
  display: inline-block;
  opacity: 1;
  transition: 0.2s all ease-in-out;
  border-radius: 5px;
  background-size: cover;
  background-color: #ddd;
  background-position: center center;
}
input:checked+.create-widget_image{
  -webkit-box-shadow: 0px 0px 9px 1px rgb(0 0 0 / 31%);
-moz-box-shadow: 0px 0px 9px 1px rgb(0 0 0 / 31%);
box-shadow: 0px 0px 9px 1px rgb(0 0 0 / 31%);
}
.basic-single_width {
  min-width: 200px !important;
}

.delbtn2 {
  margin-left: 20px !important;
}

.not-show-btn-on-desktop {
  display: none !important;
}

header {
  z-index: 3000 !important;
}

.imag_lang {
  margin-right: 5px;
}

.billing-lable {
  font-size: 0.897rem !important;
  font-weight: 500 !important;
  word-spacing: 0.01em !important;
  text-transform: uppercase !important;
  margin: 0 !important;
}

.subscription-table999 {
  clear: both;
  display: inline-block;
  /* overflow: hidden; */
  white-space: nowrap;
}

.edit_widiget_formt_config {
  padding-top: 16px;
  padding-bottom: 16px;
}

#outer {
  display: flex;
}

.resetModal {
  background: linear-gradient(
    90deg,
    #f7c129,
    #fc8031 0,
    #fd2d2d 88%
  ) !important;
  color: #ffffff !important;
  background: linear-gradient(90deg, #585ce9 0%, #7b37f9 91%);
  line-height: initial !important;
  white-space: nowrap !important;
}

.updateclose {
  /* background-color: #2e7d32 !important; */
  margin-right: 32px !important;
  color: #ffffff !important;
  line-height: initial !important;
  white-space: nowrap !important;
}

.update {
  background: linear-gradient(90deg, #585ce9 0%, #7b37f9 91%) !important;
  color: #ffffff !important;
  background: linear-gradient(90deg, #585ce9 0%, #7b37f9 91%) !important;
  line-height: initial !important;
  white-space: nowrap !important;
}

.CookieConsent {
  background: black !important;
  z-index: 3000 !important;
}

.display-inline-table {
  display: inline-table;
}

.MuiTooltip-popper {
  z-index: 2000 !important;
}

#demo-popup-popover {
  z-index: 3000 !important;
}

.zindex3000 {
  z-index: 3000 !important;
}

#menu- {
  z-index: 3000 !important;
}

@media only screen and (max-width: 750px) {
  .single-data-999 {
    margin: 0 !important;
  }

  .fontSize13px {
    font-size: 13px !important;
  }

  .display-inline-table {
    display: block;
  }

  .second-list2 {
    margin: 0 9px 0 0 !important;
  }
  .not-show-btn-on-desktop {
    display: inline-block !important;
  }

  #outer {
    display: block;
  }

  .top-feature-lead {
    display: none !important;
  }

  .modal-modal-description-code {
    min-width: 100% !important;
    height: 100% !important;
    overflow: scroll !important;
  }

  .padding-div-btn {
    padding-top: 20px !important;
  }
  .emailLabel {
    margin-bottom: 0 !important;
  }
  .section-create-widget {
    /* margin: 0 -5px 0 0; */
  }
  .paddingRightContainer {
    padding-right: 16px !important;
  }

  .select__options2 .select__control {
    width: 165px;
  }

  .select__options2 {
    width: 150px;
  }
  .select__options .select__control {
    width: 165px;
  }
  .delete_lead_back_button {
    padding-left: 14px !important;
    font-size: 10px !important;
    padding: 6px 8px !important;
  }
  .scroll_block {
    max-width: 100% !important;
    overflow-x: hidden !important;
  }
  .basic-single_width {
    min-width: 100px !important;
  }
  .padding-div-btn2 {
    padding-bottom: 20px !important;
  }
  .display_block_mob {
    display: flex;
  }
  .setting_title_header {
    display: none !important;
  }
  .reduce_padding_audit {
    margin-bottom: -12px !important;
  }

  .display-inline-block {
    display: block !important;
  }
  .display-none-mobile {
    display: none !important;
  }
  .justify-btn-left a {
    justify-content: start !important;
  }
  .wrap__page {
    padding: 10px !important;
  }
  .radio-img2 {
    margin-left: 0 !important;
  }
  .languageHeader {
    margin: 0 !important;
    margin-right: 28px !important;
  }
  .types__box {
    display: contents !important;
  }
  .foot-cnt > a::after {
    content: "\a";
    white-space: pre;
  }
  .line-break {
    display: block !important;
  }
  .delbtn2 {
    padding: 0;
    margin: "auto 0";
    margin-bottom: 0 !important;
    white-space: nowrap !important;
  }
  /* .select__options2 .select__control {
    width: 100px !important;
  } */

  .types__box {
    display: "inherit";
  }
  .not-show-btn {
    display: none !important;
  }
  .liwidth100 {
    width: 100% !important;
  }
  .signin_top {
    min-width: 109% !important;
    margin-left: -16px !important;
  }
  .dashboard-table-64 {
    display: block;
  }
  /* .emailAccount_data {
    width: 152% !important;
  } */
  .email_change_languge {
    margin-left: 55px !important;
  }
  .checkbox_all-Leads {
    display: none !important;
  }
  .signin_top2 {
    margin-top: 15px !important;
  }
  .imagegmail image {
    display: none !important;
  }
  .email_notification_buton {
    display: none !important;
  }
  .delete_autit_icon {
    display: none !important;
  }
  .auditData_box_padding {
    padding-right: 7px !important;
  }
  /* .homeaudit {
    min-width: 109% !important;
    margin-left: -16px !important;
  } */
  .section-pricing-full .pricing-box-full .pricing-box .box-full .pricing-sticky-header{
    top: 58px !important;
  }
  .section_heading_2 {
    margin-top: 20px !important;
  }
  .mob_chat {
    display: none !important;
  }
  .ranking-btns {
    display: block !important;
  }
  .formsignin {
    margin-right: 0px !important;
  }
  .settingLi {
    padding-left: 0px !important;
  }
  .envelope {
    width: 30px !important;
    height: 30px !important;
    line-height: 30px !important;
    border-radius: 50% !important;
    background: #283b9a !important;
    color: #fff !important;
    text-align: center !important;
  }
  .basic-single_input {
    width: 188px !important;
  }

  .basic-single_input_2 {
    width: 80% !important;
  }
  .createProject_warning {
    margin-top: 15px !important;
  }
  .form-group_input_signing {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .text-center-register-message {
    /* padding-right: 35px !important; */
  }
  .section-padding_paragh {
    padding-bottom: 20px !important;
  }
  .form-row-padding {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .box_full_padding {
    padding-left: 25px !important;
  }
  .keyword_select {
    width: 95% !important;
    margin-left: 3px !important;
  }
  .engine_select {
    width: 95% !important;
    margin-left: 3px !important;
  }
  .supportbtn {
    padding-right: 0px !important;
  }
  .billing_padding {
    margin-left: 28px !important;
  }
  .mange_padding {
    padding: 0px !important;
  }
  .mangebutton {
    /* margin-left: 26px !important;
    margin-right: 8px !important; */
    padding: 0 !important;
  }
  .box_show2 {
    width: 100% !important;
  }
  .whtelabelbut {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .main-li {
    padding-right: 0px !important;
  }

  .featureGrid {
    display: center !important;
  }
  .hideRanking {
    margin-bottom: 50px !important;
    width: 100% !important;
  }
  .rankingfiledmargin {
    margin-left: 16px !important;
  }
  .generated_field {
    margin-top: 25px !important;
  }
  .rankingFiledownload {
    margin-bottom: 20px !important;
    margin-left: 20px !important;
  }
  .list_ley_ranking {
    display: block !important;
    overflow-x: auto !important;
    white-space: nowrap !important;
  }
  .Auditpage_input {
    /* margin-left: 4px !important; */
  }
  .language_mb {
    min-width: 103% !important;
  }
  .audit_title_P {
    margin-left: 2px !important;
  }
  .subbodywebsite_data {
    justify-content: flex-start !important;
  }
  .fas-class {
    color: white !important;
    padding-left: 6px !important;
    padding-top: 7px !important;
  }
  .sinebx_padding {
    padding-left: 71px !important;
  }
  .btn_home_page {
    padding-right: 0px !important;
  }

  .btn_input_home {
    width: 100% !important;
    margin-left: 15px !;
  }
  .widgetImage {
    margin-left: 0 !important;
    /* margin-left: 0px !important; */
  }

  .red {
    background-color: #ff1555 !important;
    color: white !important;
    border-radius: 5px !important;
    padding-top: 2px !important;
  }
  .ranked-by2 {
    margin-bottom: 11px !important;
  }
  .generated_field2 {
    /* margin-top: 24px !important; */
  }
  .site_title_1 {
    padding-left: 5px !important;
  }
  .AuditClass_tbody {
    padding-left: 10px !important;
    padding-right: 6px !important;
  }
  .tr_border_class {
    border: 1px solid #ccc !important;
  }
  .lead-info2 {
    margin-left: 35px !important;
    padding-left: 35px !important;
  }
  .red_additional {
    padding: 0px 8px !important;
    padding-top: 2px !important;
  }
  .fas-class-additional {
    padding-right: 5px !important;
  }
  .green_duplicate {
    background: #44c872 !important;
    width: 25px !important;
    height: 28px !important;
    line-height: 25px !important;
    border-radius: 5px !important;
    text-align: center !important;
    cursor: pointer !important;
    font-size: 13px !important;
    margin: 0 5px !important;
  }
  html,
  body {
    margin: 0 !important;
    height: 100% !important;
    overflow-x: hidden !important;
  }

  .edit_keyword_sub_table {
    margin: 0 !important;
    height: 100% !important;
    overflow-x: hidden !important;
  }
  .paddingclass {
    padding: 0px !important;
  }
  .options__top_audit {
    padding-left: 10px !important;
  }
  .options__top_audit {
    padding-bottom: 0 !important;
  }

  .options__top {
    padding-left: 10px;
  }
  .audittable_column {
    padding: 25px 21px !important;
  }
  .website_audit_project {
    min-width: 109% !important;
    margin-left: -16px !important;
  }
  .website_audit_run {
    min-width: 114% !important;
    margin-left: -16px !important;
    padding-left: 56px !important;
  }
  .create_form_list {
    min-width: 100% !important;
    margin-left: -3px !important;
  }
  .single-data {
    padding-left: 10px !important;
  }
  .homeaudit_padding {
    padding: 4px 2px !important;
  }
  .createwidget_reset_button {
    /* margin-top: 20px !important; */
    /* min-width: 114% !important; */
    /* margin-left: -16px !important; */
    /* padding-left: 16px !important;
    padding-right: 16px !important; */
  }
  .website-audit_box {
    margin-top: 20px !important;
    padding: 4px 2px !important;
  }
  .css-1x2f0b-MuiPaper-root {
    /* min-width: 103% !important; */
    /* margin-left: -16px !important; */
  }
  .setting_emailform {
    margin-left: 5px !important;
  }

  .css-1hzh11g {
    min-width: 103% !important;
    margin-left: -16px !important;
  }
  .updateclosebutton-btn {
    width: 160px;
  }

  .margin-16px {
    margin: -16px !important;
  }

  .widget-form-width {
    padding: 16px !important;
    min-width: 100% !important;
  }
  .widget-form-margin {
    margin-left: -16px;
    margin-right: -16px;
  }
  .image {
    display: none !important;
  }
  .dashbor_keyword {
    box-shadow: 0px 0px 1px 1px rgb(133 133 179 / 14%) !important;
    padding: 0 15px !important;
    border-radius: 4px !important;
    background-color: #fff !important;
    margin-bottom: 15px !important;
  }
  .pagination-mobile {
    display: none !important;
  }
  .auditpading {
    padding-right: 0px;
    padding-left: 20px;
    width: 97%;
  }
  .login .signin__form {
    min-width: 300px !important;
    margin-top: 15px !important;
  }
  .boxOneTitle {
    /* padding-left: 20px !important; */
  }
  .data__box3 h4 {
    margin-top: 4px !important;
    margin-bottom: 3px !important;
  }
  .table_width {
    width: 100% !important;
  }
  .table_data {
    display: flex !important;
    width: 95% !important;
    justify-content: start !important;
  }
  .table_data_condition {
    display: flex !important;
    width: 120% !important;
    justify-content: start !important;
  }
  .searchrankingTable {
    display: none !important;
  }

  .table_data_span {
    flex: 0.7 !important;
    min-width: 100px !important ;
    font-weight: bold !important;
  }
  .lead-margin {
    /* margin-left: 27px !important; */
  }
  .table_action_button {
    margin: 13px !important;
  }
  hr.dashboard_table_mob {
    display: contents !important;
  }
  .dashboard_table_mob {
    display: contents !important;
  }
  .dashboard_table_des {
    display: none !important;
  }
  .dashboard_table_deskop_keyword_table {
    display: none !important;
  }

  .PricingIntro {
    font-size: 14px !important;
    color: #8d8d8d !important;
  }
  .dashboard_table_des_table {
    display: none !important;
  }
  .section-all-leads {
    margin-bottom: 5px !important;
  }
  .dlt-btn {
    margin-left: 35px !important;
  }
  .lead-info {
    text-align: left !important;
    padding: 3px !important;
  }
  .lead-info3 {
    text-align: left !important;
    padding: 3px 3px 3px 20px !important;
  }

  .css-2bi4m2-MuiContainer-root {
    margin-left: 0px !important;
  }

  .buttons {
    min-width: 100px !important;
  }
  .makeStyles-root-7 .seo_btn {
    min-width: 9em !important;
  }
  .MuiButton-textSizeSmall {
    min-width: 100px !important;
  }
  .jss1 {
    padding: 8px 22px !important;
  }
  .makeStyles-root-8 .seo_btn {
    width: 70% !important;
  }
  .signInButton {
    min-width: 8em !important;
  }
  .jss8 {
    width: 100% !important;
  }
  .makeStyles-root-7 .seo_btn {
    min-width: 9em !important;
  }
  .tryitfree {
    padding: 7px 12px !important;
  }
  .jss1 {
    padding: 7px 12px !important;
  }
  .CookieConsent {
    min-width: 68px !important ;
    position: relative !important;
    top: 50px !important;
  }
  .my-audit {
    display: contents !important;
    justify-content: center !important;
  }
  .editLabel {
    font-size: 8px !important;
    font-weight: 600 !important;
    width: 8px !important;

    width: 121px !important;
    justify-content: flex-end !important;
  }
  .paddingTop10px {
    padding-top: 10px !important;
  }
  .saveAccountButton {
    /* margin-top: 32px !important; */
    margin-bottom: 16px;
    min-width: 175px !important;
  }
  .allleadsHeading {
    display: contents !important ;
    align-items: center !important;
    justify-content: center !important;
  }
  .site-list-table {
    overflow-x: hidden !important;
  }
  #outer {
    width: 100% !important;
    text-align: center !important;
  }
  .inner {
    margin-bottom: 7px !important;
    /* margin-left: 12px !important;
    padding-right: 41px !important; */
  }
  .resetModal {
    width: 100% !important;
    background: linear-gradient(
      90deg,
      #f7c129,
      #fc8031 0,
      #fd2d2d 88%
    ) !important;
    color: #ffffff !important;
    background: linear-gradient(90deg, #585ce9 0%, #7b37f9 91%);
    line-height: initial !important;
    white-space: nowrap !important;
    margin: 0;
  }
  .update {
    width: 100% !important;
    background: linear-gradient(90deg, #585ce9 0%, #7b37f9 91%) !important;
    color: #ffffff !important;
    background: linear-gradient(90deg, #585ce9 0%, #7b37f9 91%) !important;
    line-height: initial !important;
    white-space: nowrap !important;
    margin: 0;
  }
  .updateclose {
    background-color: #2e7d32 !important;
    width: 100% !important;
    /* margin-right: 32px !important; */
    color: #ffffff !important;
    line-height: initial !important;
    white-space: nowrap !important;
    margin: 0;
  }
  .widgetsave {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .configureForm {
    margin-left: 15px !important;
  }
  .custom-selector2 {
    /* width: 96% !important; */
    /* margin-left: 15px !important; */
  }
  .custom_checkbox_check {
    display: flex !important;
    width: 100% !important;

    align-items: center !important;
  }
  .section-heading2 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .custom-selector3 {
    /* width: 94% !important; */
    /* margin-left: 2px !important; */
  }

  .formMain_table {
    display: inline !important;
  }
  .widgetAction_btn {
    height: 26px !important;
  }
  /* .allleadsHeading {
    padding-left: 10px !important;
  } */
  .tablemarginTop {
    margin-top: 10px !important;
  }
  .basicSingle {
    width: 100px;
  }
  .createWidgetList {
    padding-bottom: 20px !important ;
  }
  .sub-body {
    white-space: normal !important;
    display: contents !important;
  }
  .sub-body-button {
    justify-content: flex-start !important;
  }
  .sub-data .actions {
    justify-content: flex-start !important;
  }
  .updateclosebutton {
    /* margin-left: 41px !important; */
    display: flex;
    justify-content: space-between;
  }
  .pleasetextbox-div {
    display: none;
  }
  .pleasetextbox {
    margin-left: 15px !important;
  }
  .edit_widiget_formt_config {
    padding: 16px !important;
  }
  .modal-dialog {
    margin: 0px !important;
  }
  .emailnotification {
    display: flex !important;
    width: 100% !important;
    justify-content: space-evenly !important;
    align-items: center !important;
  }
  .seo-logo2 {
    padding-bottom: 4px !important;
  }
  .languageHeader1 {
    padding-top: 10px !important;
  }
  .section-site-list
    .site-list-table
    .site-list-body
    .list-body-full
    .sub-body
    .sub-data:nth-child(5) {
    justify-content: flex-start !important;
  }
  .section-site-list .site-list-table .site-list-head .list-head-title {
    display: contents !important;
  }
  /* .MuiDrawer-paperAnchorBottom {
      height: 31em !important;
      width: 305px !important;
      bottom: 0;
    } */
  .languageHeader {
    padding-left: 46px !important;
  }
  /* .MuiGrid-item {
     padding-left: 4px !important;
      margin-left: 1px !important; 
    padding-top: 21px !important;
  } */
  .editInputField {
    max-width: 119px !important;
  }
  .language__select__Profile {
    margin-top: 0px !important;
  }
  .passwordInput {
    margin-top: -21px !important;
  }
  /* 
    .MuiOutlinedInput-root {
      width: 126% !important;
    }  */
  .emailAccount {
    max-width: 179px !important;
  }
  .accountLabel {
    justify-content: center !important;
  }
  .add-line {
    margin-top: 10px !important;
  }
  .changelog {
    overflow: hidden !important;
  }
  .css-1s50f5r {
    -webkit-flex-basis: 50% !important;
    -ms-flex-preferred-size: 50% !important;
    flex-basis: 46% !important;
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
    min-width: 97% !important;
    display: flex !important;
  }
  .settingTab {
    overflow: scroll !important;
  }
  .settingPaper {
    width: 100% !important;
    overflow: scroll !important;
  }
  .css-jpln7h-MuiTabs-scroller {
    overflow: scroll !important;
  }
  .css-1anid1y {
    overflow: scroll !important;
  }
  .MuiDrawer-paperAnchorBottom {
    /* left: 50% !important;
      top: 50% !important; */
    width: 84% !important;
    /* transform: translate(-50%, -50%) !important; */
  }
  .section-site-list
    .site-list-table
    .site-list-body
    .list-body-full
    .list-main-body
    .main-body-data
    .single-data {
    min-width: 0px !important;
    white-space: nowrap !important;
    margin: 0px -27px;
  }
  .css-1h09ct9 {
    display: inline-flex !important;
  }
  .css-vok43g-MuiGrid-root {
    flex-basis: 102% !important;
    max-width: none !important;
  }
  .css-45ujxc {
    flex-basis: 102% !important;
    max-width: none !important;
  }
  .sidebar {
    z-index: 1000;
  }
  .mobileFooter {
    display: none !important;
  }
  .branded__seo {
    display: none !important;
  }
  .mobilePagination {
    display: none !important;
  }
  .css-70qvj9 {
    display: list-item !important;
  }
  .jss663.MuiButton-containedSizeMedium {
    width: 52% !important;
    margin: 3px !important;
  }
  .navbar2 {
    padding: 8px 0 !important;
  }
  .sup_btn_with_margin {
    margin-left: -35px !important;
    margin-right: -35px !important;
  }
  .note_box-2 {
    padding-bottom: 0px !important;
  }
  .branded__seo {
    display: none;
  }
  .css-14jkshp {
    margin-left: 0 !important ;
    /* padding-right: 0 !important; */
  }
  .css-1dziyhg {
    /* width: 48% !important; */
    /* margin-bottom: 5px !important; */
  }
  .css-77vupy {
    margin-left: 0 !important;
    margin: 3px !important;
  }
  .jss515 {
    width: 145%;
  }
  .makeStyles-root-29 {
    width: 145%;
  }
  .title10x {
    display: none !important;
  }
  .datatabledelte {
    display: contents !important;
    margin-left: 4px !important;
    flex: 0.8 !important ;
  }
  .css-1osj8n2-MuiGrid-root {
    flex-basis: 102% !important;
    max-width: none !important;
  }
  .homeCommon {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  }
  .dashboardTable {
    display: contents !important;
  }

  .section-heading99 {
    margin-bottom: 6px !important;
  }
  #stepBar {
    top: 64px;
    z-index: 5;
    /* position: sticky; */
  }

  #stepBar--top {
    height: 5em !important;
    box-shadow: 0 10px 10px 1px rgb(155 155 155 / 20%) !important;
    background-color: #fff !important;
  }

  .heading-keyword {
    /* margin-bottom: -18px !important; */
  }
  .css-b8ldur-Input {
    width: 100px !important;
  }
  .container-fluid {
    /* padding-right: 15px !important;
    padding-left: 7px !important; */
  }
  .audittablewidth {
    width: 100% !important;
  }
  .allcreatePage {
    padding-right: 7px !important;
    padding-left: 7px !important;
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: #ffffff;
  }

  .searchengineButton {
    margin-left: 10px;
    width: 73% !important;
  }
  .edit_keyword_back_key {
    margin-left: 10px;
    width: 46% !important;
  }
  .arrow {
    /* display: none !important; */
  }
  .searchengineheight {
    height: 49px !important;
  }
  .searchenginemidbox {
    display: inline-block !important;
    margin-left: 0rem !important;
  }
  .searchenginemidbox2 {
    margin-left: -3rem !important;
  }
  .messagemobileicon {
    font-size: 12px !important;
  }
  .widgetDeleteButton {
    margin-left: 5em !important;
  }
  .widgetTablebutton {
    display: flex;
  }
  .copy__code {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  /* THIS CSS IS OVERRIDING ON TABLE START*/

  .dashboard-table-1 thead,
  .dashboard-table-1 tbody,
  .dashboard-table-1 th,
  .dashboard-table-1 td,
  .dashboard-table-1 tr {
    display: block;
    white-space: normal !important;
  }

  .dashboard-table-1 thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .dashboard-table-1 td {
    border: none;
    /* border-bottom: 1px solid #eee; */
    position: relative;
    padding-left: 5%;
  }

  .dashboard-table-1 td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
  .td_audit_class {
    border: none !important;
  }
  hr.divider {
    margin: 0em;
  }

  .section__payment .payment-box .card .info ul li .flex__items {
    padding: 10px 0;
    flex: 1 1;
  }

  .section__payment .payment-box .card .info ul li {
    border-color: currentcolor #e8e8e8 #e8e8e8 !important;
    border-top: 1px solid #e8e8e8 !important;
    display: flex !important;
  }
  .section__payment .payment-box .card .info ul li .flex__items:nth-of-type(2) {
    border-left: 1px solid #f0f0f0;
    padding-left: 15px;
    margin-left: 20px;
  }
  .logo_ext {
    padding-top: 12px !important;
    padding-left: 5px !important;
  }
  .select__options_delete {
    width: 48% !important;
  }
  .select__options_delete_button {
    font-size: 10px !important;
    margin-left: 9px !important;
    width: 45% !important;
  }
  .select__options_auditdelete_button {
    margin-left: 12px !important;
  }
  .delete_widget_padding {
    padding: 4px 10px !important;
  }
  .delete_widget_padding2 {
    padding-left: 27px !important;
  }
  .checkbox_delete_span {
    margin-left: 6px !important;
  }
  .pricing_option_list_group label .MuiTypography-root{
    padding: 2px 13px !important;
    font-size: 12px !important;
  }
  /* 
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
    white-space: normal !important;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 5%;
  }

  td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  } */

  /*
  .d_progress_bar_show {
    display: none !important;
  }

  .d_progress_bar_non {
    display: flex !important;
    flex: 0.8 !important ;
  }
  .css-1osj8n2-MuiGrid-root {
    flex-basis: 102% !important;
    max-width: none !important;
  }

  .progress {
    margin-left: 15px !important;
  } */

  /* THIS CSS IS OVERRIDING ON TABLE END*/
}

.keyword-ranking *,
.liststyletype * {
  list-style-type: none !important;
}

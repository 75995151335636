@mixin noteBox {
  .note {
    display: flex;
    align-items: center;
    background: #fffacd;
    padding: 10px 15px;
    padding-left: 5px;
    position: relative;
    z-index: 5;

    .note-icon {
      img {
        max-width: initial;
        margin-left: 5px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 100%;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      background: #e7e2ae;
    }

    p {
      font-size: 13px;
      margin: 0;
      padding-left: 10px;
      line-height: 20px;
      letter-spacing: initial;
      a {
        color: #2d80fd;
      }
    }
  }
}

.content-wrapper {
  width: 100%;
  min-height: calc(100vh - 50px);

  &.burger {
    .sidebar {
      width: 75px;
    }

    .main-content {
      margin-left: 80px;
    }
  }

  width: 100%;
  display: block;

  .main-content {
    margin-left: 250px;
    margin-top: 62px;
    // transition: 0.4s all ease-in-out;

    .note-box {
      @include noteBox();
    }
  }
}

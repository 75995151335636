@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;600;700;800&display=swap");

@function rem($value) {
  @return unquote(($value/1px)/16+"rem");
}

$colors: (
  "white": "#FFFFFF",
  "gray": "#F9F8FA",
  "darkGray": "#B7B7B7",
  "fontGray": "#8D8D8D",
  "black": "#000000",
  "midBlack": "#383838",
  "blue": "#585CE9",
  "orange": "#EC8023",
  "green": "#10bb35",
  "red": "#ff4921",
);

@each $colorName, $colorValue in $colors {
  .bg-#{$colorName} {
    background-color: #{$colorValue};
  }

  .text-#{$colorName} {
    color: #{$colorValue};
  }
  .text-#{$colorName}-im {
    color: #{$colorValue} !important;
  }
}

$shadowBox: 0 0 10px rgba(232, 232, 232, 0.73);

$blue: #585ce9;

$orange: #ec8023;

$green: #10bb35;

$gradientOrange: -webkit-linear-gradient(
  90deg,
  #f7c129 0%,
  #fc8031 0%,
  #fd2d2d 88%
);
$gradientOrange: -moz-linear-gradient(
  90deg,
  #f7c129 0%,
  #fc8031 0%,
  #fd2d2d 88%
);
$gradientOrange: -ms-linear-gradient(
  90deg,
  #f7c129 0%,
  #fc8031 0%,
  #fd2d2d 88%
);
$gradientOrange: -o-linear-gradient(90deg, #f7c129 0%, #fc8031 0%, #fd2d2d 88%);
$gradientOrange: linear-gradient(90deg, #f7c129 0%, #fc8031 0%, #fd2d2d 88%);

$reverseOrange: -webkit-linear-gradient(
  90deg,
  rgba(250, 116, 27, 0.986) 9%,
  rgba(252, 89, 49, 1) 67%,
  rgba(247, 151, 41, 1) 100%
);
$reverseOrange: -moz-linear-gradient(
  90deg,
  rgba(250, 116, 27, 0.986) 9%,
  rgba(252, 89, 49, 1) 67%,
  rgba(247, 151, 41, 1) 100%
);
$reverseOrange: -ms-linear-gradient(
  90deg,
  rgba(250, 116, 27, 0.986) 9%,
  rgba(252, 89, 49, 1) 67%,
  rgba(247, 151, 41, 1) 100%
);
$reverseOrange: -o-linear-gradient(
  90deg,
  rgba(250, 116, 27, 0.986) 9%,
  rgba(252, 89, 49, 1) 67%,
  rgba(247, 151, 41, 1) 100%
);
$reverseOrange: linear-gradient(
  90deg,
  rgba(250, 116, 27, 0.986) 9%,
  rgba(252, 89, 49, 1) 67%,
  rgba(247, 151, 41, 1) 100%
);

$gradientBlue: linear-gradient(
  90deg,
  rgba(106, 83, 232, 1) 0%,
  rgba(91, 90, 232, 1) 0%,
  rgba(123, 73, 230, 1) 85%
);
$reverseBlue: linear-gradient(
  90deg,
  rgba(123, 73, 230, 1) 0%,
  rgba(91, 90, 232, 1) 0%,
  rgba(106, 83, 232, 1) 85%
);

$easeTime: -webkit-transition 0.3s all ease-in-out;
$easeTime: -moz-transition 0.3s all ease-in-out;
$easeTime: -ms-transition 0.3s all ease-in-out;
$easeTime: -o-transition 0.3s all ease-in-out;
$easeTime: 0.3s all ease-in-out;

// all mixin start
@mixin btnOrange {
  background-image: $gradientOrange;
}

// end carousel dot

// start collapse card
@mixin colCard {
  .card {
    border: none;

    .card-header {
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      border-radius: 0;

      .btn-link {
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        text-align: initial;
        text-decoration: none;
        width: 100%;
        display: block;

        &.collapsed {
          @extend .text-midBlack;
        }
      }
    }

    .card-body {
      background: none;
      border: none;
      margin: 0;
      padding: 0;
    }
  }
}

// end collapse card

// start counter section
@mixin counterCnt {
  .ac-content {
    .section-heading {
      h4 {
        position: relative;
        padding-bottom: 30px;
        margin-bottom: 30px;

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 35px;
          height: 2px;
          background: $orange;
        }
      }
    }

    .ac-desc {
      p {
        font-size: 16px;
        @extend .text-midBlack;
        margin-bottom: 40px;
      }
    }

    .ac-count {
      display: flex;
      align-items: center;

      span {
        @extend .text-midBlack;
      }

      .cnt-single {
        margin: 0px 40px;

        .counter {
          padding-top: 15px;

          &.bdr {
            border-right: 4px solid #ededed;
            padding-right: 80px;
          }

          span {
            font-size: 32px;
            font-weight: 400;
            color: $orange;

            i {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .btn-trial {
    margin-top: 50px;

    .button-trial {
      background: $gradientOrange;
      text-transform: inherit;
      padding: 8px 35px;

      &:hover {
        background: $reverseOrange;
      }
    }
  }
}

// end counter section

.gradient__orange {
  @include btnOrange();
}

a.seo_btn {
  color: #fff !important;
  &:hover {
    color: #fff !important;
    text-decoration: none;
  }
  &:focus {
    outline: none;
    box-shadow: initial;
  }
}

.text__orange {
  color: #ff9539;
}
.text__red {
  color: #ff4921;
}
// option box
@mixin optionBox {
  margin-top: rem(100px);

  p {
    text-align: center;
    margin-top: rem(20px);
    @extend .text-white;
  }

  .announce {
    display: none;
  }

  .box-cnt {
    .input-option {
      width: 100%;
      background: #fff;
      border-radius: 50px;
      padding: 10px;
      box-shadow: 0px 2px 100px 15px rgba(214, 208, 208, 0.6);

      .search {
        padding-left: 10px;

        .form-group {
          margin-bottom: 0;
        }

        .input-url {
          .icon {
            position: absolute;
            top: 15px;
            left: 8px;
            padding-left: 5px;
            font-size: 20px;

            img {
              padding-left: 5px;
              margin-left: 5px;
              margin-right: 5px;
            }
          }

          input {
            border: none;
            padding: 0px 0px 0px 55px;
            margin-top: 5px;
            border: 1px solid #dedede;

            &::-webkit-input-placeholder {
              font-size: 13px;
              @extend .text-midBlack;
            }

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }
      }

      .btn-explore {
        text-align: right;

        .explore {
          position: relative;
          box-shadow: 0 10px 30px rgba(255, 135, 119, 0.64);
          @include btnOrange();
        }
      }
    }
  }
}

// option box

// tik mark
@mixin tikMark {
  &:after {
    content: "";
    position: absolute;
    top: 6px;
    left: -10px;
    display: inline-block;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    height: 10px;
    width: 5px;
    border-bottom: 2px solid #585ce9;
    border-right: 2px solid #585ce9;
  }
}

// tik mark

// end mixin start

$reverseBlue: linear-gradient(
  90deg,
  rgba(123, 73, 230, 1) 0%,
  rgba(91, 90, 232, 1) 0%,
  rgba(106, 83, 232, 1) 85%
);

.gradient_blu {
  background: linear-gradient(
    90deg,
    rgba(88, 92, 233, 1) 0%,
    rgba(123, 55, 249, 1) 91%
  );
}

.gradient_green {
  background: linear-gradient(
    90deg,
    rgba(68, 200, 114, 1) 0%,
    rgba(9, 196, 74, 1) 91%
  );
  &:hover {
    color: #fff;
  }
}

.seo_btn {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  @extend .text-white;
  @extend .bg-blue;
  border-radius: 4px;
  padding: 8px 35px;
  border: none;
  transition: $easeTime;

  &.p_16 {
    padding: 8px 16px;
  }

  &:hover {
    color: #fff;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.loader {
    position: relative;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    span#loader {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
    }
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  a {
    color: #fff;
  }

  &.loader.btn__anchor {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  &.btn__anchor {
    padding: 0 !important;
    margin: 0;
    a {
      padding: 2px 5px;
      display: block;
    }
  }

  &.flex {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 20px;
      color: #fff;
      margin-right: 15px !important;
    }
  }
}

.btn_small {
  padding: 5px 20px;
  font-size: 13px;
  border-radius: 5px;
}

.btn_xs {
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 4px;
}

.btn_red {
  background: #ff5151 !important;
}

.layout_white {
  background: #fff;
  border-radius: 5px;
  padding: 30px 25px;
}

button {
  border-radius: 8px;
  border: 1px solid #ddd;
}

body {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: rem(24px);
  letter-spacing: 0.5pt;
  background: #f9f9f9;
  @extend .text-midBlack;
}

img {
  max-width: 100%;
  display: block;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  @extend .text-fontGray;
  transition: $easeTime;

  &:hover {
    text-decoration: none;
    @extend .text-midBlack;
  }
}

.box__shadow {
  box-shadow: 0 0 1px 1px rgba(133, 133, 179, 0.14);
}

.seperator {
  padding: 100px 0px;
  transition: 0.5s all ease-in-out;
}

h1 {
  @extend .text-white;
  font-size: 54px;
  font-weight: 700;
}

p {
  line-height: rem(24px);
  letter-spacing: 0.5pt;
}

.section-heading {
  h2 {
    font-size: rem(22px);
    font-weight: 600;
    @extend .text-black;

    &.bfr {
      position: relative;
      display: block;
      text-align: center;
      padding-bottom: 30px;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        width: 40px;
        @extend .bg-orange;
      }
    }
  }

  h4 {
    font-size: rem(36px);
    @extend .text-midBlack;
    font-weight: 500;
  }

  p {
    font-size: 14px;
    @extend .text-fontGray;

    &.ask {
      text-transform: uppercase;
    }

    &.white {
      @extend .text-white;
      font-size: rem(20px);
    }
  }
}

.form_design {
  .form-group {
    position: relative;
  }
  .form-full {
    padding-bottom: 40px;

    .form-row > .col,
    .form-row > [class*="col-"] {
      padding-right: 30px;
    }

    .custom-selector {
      input {
        height: 30px;
      }
    }

    input,
    textarea {
      width: 100%;
      height: 40px;
      border: none;
      background: #fff;
      border: 1px solid #d7d5e1;
      border-radius: 3px;
      padding: 20px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        font-size: 13px;
        color: #929292;
      }
    }

    textarea {
      height: auto !important;
    }
  }
}

// start home page design
.page-wrapper {
  // start main menu design

  &.burger .seperator {
    transform: translateX(-100%);
    opacity: 0.5;
  }

  .main-menu {
    position: fixed;
    top: 0px;
    right: 0;
    min-height: 100vh;
    min-width: 100%;
    background: #fff;
    box-shadow: $shadowBox;
    z-index: 2;
    transform: translateX(100%);
    transition: 0.5s all ease-in-out;

    &.nav-active {
      transform: translateX(0) !important;
    }

    .menu {
      padding: 80px 50px 0;

      .side-menu {
        .nav {
          display: block;
          margin-top: 40px;
          text-align: right;

          li {
            display: block;
            padding: 10px 5px;
            transition: transform 0.5s all ease-in-out;
            opacity: 0;

            &.active a {
              @extend .text-blue;
            }

            a {
              font-size: 14px;
              font-weight: 600;
              @extend .text-midBlack;

              &:hover {
                @extend .text-blue;
              }
            }
          }

          @keyframes LinkFade {
            from {
              opacity: 0;
              transform: translateX(50px);
            }

            to {
              opacity: 1;
              transform: translateX(0);
            }
          }
        }
      }
    }
  }

  // end main menu design

  &.nav-style,
  &.burger {
    .fixed-to-top,
    .burger-fixed {
      background: #fff;
      box-shadow: 0 2px 10px rgba(232, 232, 232, 0.73);

      .navbar {
        padding: 20px 0 !important;
      }

      .header {
        ul li a {
          color: #2c1111 !important;
        }

        .btn-menu {
          .line {
            background: #383838 !important;
          }
        }
      }
    }

    ul.main-ul li.main-li .btn-select li span {
      color: #383838 !important;
    }
  }

  header {
    .section-nav {
      // transition: 0.5s all ease-in-out;
      // width: 100vw;
      // position: fixed;
      // top: 0;
      // z-index: 9;

      /*for chang the logo*/
      &.fixed-to-top,
      &.burger-fixed {
        .seo-logo {
          .white-img {
            display: none !important;
          }

          .blue-img {
            display: block !important;
          }
        }
      }

      /*for chang the logo*/

      .navbar {
        width: 100%;
        display: block;
        padding: 25px 0px;
        transition: 0.4s all ease-in-out;

        .navbar-full {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .seo-logo {
            .white-img {
              width: auto;
            }

            .blue-img {
              display: none;
              width: auto;
            }
          }

          .header {
            ul.main-ul {
              display: flex;
              align-items: center;

              li.main-li {
                display: inline-block;
                padding: 0;
                position: relative;

                .ae-dropdown {
                  button {
                    box-sizing: border-box;

                    &:focus {
                      outline: 0;
                    }
                  }

                  .flag-select {
                    .flag-select__btn:after {
                      margin-top: 8px;
                    }

                    ul.flag-select__options {
                      border: none;
                      box-shadow: 0 2px 10px rgba(177, 177, 177, 0.3);
                      border-radius: 10px;
                      left: -25px;

                      li {
                        padding: 8px 25px;

                        span img {
                          display: inline-block;
                          margin-top: -10px;
                        }
                      }
                    }
                  }
                }

                &:last-child {
                  padding-right: 0;
                }

                a {
                  @extend .text-fontGray;
                  font-size: 16px;
                  font-weight: 400;

                  i {
                    margin-right: 10px;
                    position: relative;
                  }
                }
              }

              // end language selector

              // for main menu button
              li .btn-menu {
                text-align: right;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &.toggle {
                  .line {
                    background: #383838 !important;
                  }
                }

                .line {
                  width: 25px;
                  height: 3px;
                  @extend .bg-white;
                  margin: 3px 0;
                  border-radius: 2px;

                  &.line-2 {
                    width: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // end header design
}

// end home page design
